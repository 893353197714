<template>
	<div id="home">
		<img class="banner" src="../assets/images/banner.png" alt="" />

		<!-- 业务模式 -->
		<div class="BusinessModel">
			<div class="title">
				<img class="le" src="../assets/images/len.png" alt="" />
				业务模式
				<img class="ri" src="../assets/images/len.png" alt="" />
			</div>

			<div class="BusinessBox">
				<div class="cont">
					<p>
						我们致力于通过多渠道、多场景的患者流量引入，结合企业、专家、公益等多方资源，为患者提供有价值的附加服务，帮助患者打通“医、诊、药、费”的诊疗服务闭环的同时，打造基于社交的精准患者流量池。
					</p>
					<p>
						一方面拓展专家合作深度与广度，一方面从患者问诊、购药、疾病管理、病友交流、慈善资源对接等多元化场景对患者进行专业服务，同时结合互联网技术创新业务模式，解决真实痛点，提升运营效率
					</p>
					<div class="button">
						了解详情
						<svg t="1679454928764" class="icon svg1" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2749" width="16" height="16">
							<path
								d="M512 85.333333c235.637333 0 426.666667 191.029333 426.666667 426.666667S747.637333 938.666667 512 938.666667 85.333333 747.637333 85.333333 512 276.362667 85.333333 512 85.333333z m0 234.666667a32 32 0 0 0-32 32v128H352a32 32 0 0 0 0 64h128v128a32 32 0 0 0 64 0V544h128a32 32 0 0 0 0-64H544V352a32 32 0 0 0-32-32z"
								fill="#59AF44"
								p-id="2750"
							></path>
						</svg>
						<svg t="1679454928764" class="icon svg2" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2749" width="16" height="16">
							<path
								d="M512 85.333333c235.637333 0 426.666667 191.029333 426.666667 426.666667S747.637333 938.666667 512 938.666667 85.333333 747.637333 85.333333 512 276.362667 85.333333 512 85.333333z m0 234.666667a32 32 0 0 0-32 32v128H352a32 32 0 0 0 0 64h128v128a32 32 0 0 0 64 0V544h128a32 32 0 0 0 0-64H544V352a32 32 0 0 0-32-32z"
								fill="#ffffff"
								p-id="2750"
							></path>
						</svg>
					</div>
				</div>
				<img class="contImg" src="../assets/images/aboutUs.png" alt="" />
			</div>
		</div>

		<div class="cooperate">
			<div class="title">
				<img class="le" src="../assets/images/len.png" alt="" />
				合作伙伴
				<img class="ri" src="../assets/images/len.png" alt="" />
			</div>

			<div class="cooperateBox">
				<el-carousel :interval="1000" arrow="always" :autoplay="false" indicator-position="outside">
					<el-carousel-item v-for="(item, i) in cooperateList" :key="i">
						<div class="item" v-for="(link, n) in item" :key="n">
							<img :src="require(`../assets/logos/${link}`)" alt="" />
							<span class="top"></span>
							<span class="bottom"></span>
							<span class="left"></span>
							<span class="right"></span>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
	name: 'Home',
	data() {
		return {
			cooperateList: [
				['1.png', '2.png', '3.png', '4.png', '拜耳.png', '第一三.png', '瀚晖.png', '恒瑞.png', '华润.png', '赛诺菲.png', '三生.png', '圣湘.png'],
				[
					'西安杨森.png',
					'信达.png',
					'亿腾.png',
					'再鼎.png',
					'正大天晴.png',
					'bl.png',
					'Gilead-Sciences-Logo 1.png',
					'GSK_logo-1024x881 1.png',
					'image 6.png',
					'image 7.png',
					'image 8.png',
					'image 9.png'
				],
				['image 10.png', 'image 11.png', 'image 12.png', 'image 13.png', 'image 14.png', 'image 15.png', 'image 16.png', 'image 17.png', 'lilly.png', 'logo10.png']
			]
		};
	},
	components: {
		// HelloWorld
	}
};
</script>

<style lang="less" scoped>
#home {
	display: flex;
	flex-direction: column;
	.banner {
		display: block;
		width: 100vw;
	}

	.title {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-size: 36px;
		color: #2d3d29;
		margin: 90px 0;

		.ri {
			transform: rotate(180deg);
		}

		.le,
		.ri {
			margin: 0 40px;
		}
	}

	.BusinessModel {
		width: 100%;
		background: url('../assets/images/BusinessModel.png');
		background-size: 100% 100%;
		background-position: center center;
		overflow: hidden;

		.BusinessBox {
			width: 1300px;
			padding: 0 50px;
			margin: auto;
			display: flex;
			flex-direction: row;
			margin-bottom: 90px;

			.cont {
				flex: 1;
				padding: 65px 50px 0 110px;
				p {
					font-size: 15px;
					line-height: 2;
					margin-bottom: 30px;
					text-indent: 30px;
				}

				.button {
					width: 152px;
					height: 43px;
					border-radius: 80px 80px 80px 80px;
					opacity: 1;
					border: 1px solid #59af44;
					color: #59af44;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 16px;
					cursor: pointer;
					transition: all 0.3s;
					position: relative;
					padding-right: 20px;
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

					.icon {
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(30px, -8px);
					}

					.svg1 {
						opacity: 1;
					}

					.svg2 {
						opacity: 0;
					}

					* {
						cursor: pointer;
					}

					&:hover {
						background-color: #59af44;
						color: #fff;
						box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

						.svg1 {
							opacity: 0;
						}

						.svg2 {
							opacity: 1;
						}
					}
				}
			}

			.contImg {
				width: 660px;
			}
		}
	}

	.cooperate {
		width: 100%;
		background: url('../assets/images/cooperate.png');
		background-size: 100% 100%;
		background-position: center center;

		.cooperateBox {
			width: 1300px;
			margin: auto;
			height: 500px;
			margin-bottom: 90px;

			/deep/.el-carousel__container {
				width: 100%;
				height: 500px;

				.el-carousel__item {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;

					.item {
						width: 220px;
						height: 120px;
						display: flex;
						justify-content: center;
						align-items: center;
						margin: 10px 25px;
						background-color: #fff;
						border-radius: 10px;
						padding: 20px;
						box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
						transition: all 0.3s;
						position: relative;

						> span {
							position: absolute;
							transition: all 0.3s;
						}
						.top {
							top: 0%;
							left: 0%;
							right: 100%;
							height: 1px;
							background-color: #59af44;
						}
						.bottom {
							bottom: 0%;
							left: 100%;
							right: 0%;
							height: 1px;
							background-color: #59af44;
						}
						.left {
							bottom: 100%;
							left: 0%;
							top: 0%;
							width: 1px;
							background-color: #59af44;
						}
						.right {
							bottom: 0%;
							right: 0%;
							top: 100%;
							width: 1px;
							background-color: #59af44;
						}

						img {
							max-width: 160px;
							max-height: 90px;
							transition: all 0.3s;
						}

						&:hover {
							box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
							img {
								transform: scale(1.2);
							}
							.top {
								right: 0%;
							}
							.bottom {
								left: 0%;
							}
							.left {
								bottom: 0%;
							}
							.right {
								top: 0%;
							}
						}
					}
				}

				.el-carousel__arrow {
					width: 80px;
					height: 80px;
					background-color: #f7fbf6;
					i {
						font-size: 26px;
						color: #59af44;
					}

					&:hover {
						background-color: #59af44;

						i {
							color: #fff;
						}
					}
				}

				.el-carousel__arrow--right {
					right: 0;
				}

				.el-carousel__arrow--left {
					left: 0;
				}
			}
		}
	}
}
</style>
