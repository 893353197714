<template>
	<div id="app">
		<Header />
		<div class="appcont"><router-view /></div>
		<Footer />
	</div>
</template>
<script>
import Header from '@/components/webHeader.vue';
import Footer from '@/components/webFooter.vue';
export default {
	name: 'app',
	components: {
		Header,
		Footer
	},
	data() {
		return {};
	},
	created() {},
	methods: {}
};
</script>
<style lang="less">
@import url('./assets/css/reset.css');
#app {
	display: flex;
	flex-direction: column;
	.appcont {
		min-height: 500px;
	}
}
</style>
