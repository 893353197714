<template>
	<div id="header">
		<img class="imgBack" src="../assets/images/headerBack.png" alt="" />
		<div class="handCon">
			<img src="../assets/images/logo.png" alt="" class="logo" />
			<div class="nav">
				<div :class="active === i ? 'item active' : 'item'" v-for="(item, i) in nav" :key="i" @click="navClick(i)">
					<div class="cn">{{ item.cn }}</div>
					<img src="../assets/images/hover.png" alt="" />
					<div class="en">{{ item.en }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			active: 0,
			nav: [{ cn: '首页', en: 'Home' }, { cn: '关于我们', en: 'About' }]
		};
	},
	created() {},
	mounted() {},
	methods: {
		navClick(i) {
			// this.active = i;
			console.log(i);
		}
	}
};
</script>

<style lang="less">
#header {
	width: 100vw;
	height: 140px;
	background-image: linear-gradient(90deg, #fff 0%, #f4f4f4 100%);
	position: relative;
	min-width: 1300px;

	.imgBack {
		position: absolute;
		width: auto;
		height: 100%;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
	}

	.handCon {
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		height: 100%;
		width: 1300px;
		// background-color: rgba(0, 0, 0, 0.1);
		.logo {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			height: 160px;
		}
		.nav {
			width: 600px;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			height: 160px;
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			padding-top: 50px;

			.item {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin: 0 20px;
				cursor: pointer;
				* {
					cursor: pointer;
					transition: all 0.3s;
				}
				.cn {
					color: #2d3d29;
					font-size: 16px;
				}
				.en {
					color: #2d3d29;
					font-size: 16px;
				}
				img {
					width: 0;
					height: 0;
					margin: 5px 0;
				}
			}
			.active {
				.cn {
					color: #59af44;
				}
				.en {
					color: #59af44;
				}
				img {
					width: 26px;
					height: 10px;
				}
			}
		}
	}
}
</style>
